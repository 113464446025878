import React from 'react';

export const items = [
  {
    id: 1,
    size: 96,
    title: 'Ecommerce platforms',
    text:
      'Enable widest range of payment collection & disbursal options for customers',
  },
  {
    id: 2,
    size: 96,
    title: 'Software companies',
    text:
      'Integrate Cashfree with product to offer full stack payment solutions',
  },

  {
    id: 3,
    size: 96,
    title: 'Consultants',
    text: 'Enable your clients with a full stack payment solution',
  },
  {
    id: 4,
    size: 96,
    title: 'Freelancers',
    text: 'Empower customers while  securing regular commissions',
  },
  {
    id: 5,
    size: 96,
    title: 'Social media influencers',
    text: 'Advocate Cashfree and earn amazing rewards',
  },
  {
    id: 6,
    size: 96,
    title: 'Incubators & Co-working spaces',
    text: 'Help startups get started with online payments',
  },

  {
    id: 7,
    size: 96,
    title: 'VC & Investors',
    text: 'Offer a secure and seamless payment platform to portfolio companies',
  },
  {
    id: 8,
    size: 96,
    title: 'Web Developers',
    text: 'Integrate Cashfree and provide a seamless payment experience',
  },
];

export const models = [
  {
    id: 0,
    icon: '/img/partner/green.svg',
    title: 'Affiliate Partner',
    description:
      'Promote Cashfree Payments offerings to your connections and get a regular inflow of earnings',
    checklist: [
      {
        id: 0,
        main: '₹600',
        sub: 'rewarded for every payment gateway referral*',
      },
      {
        id: 1,
        main: 'Earn 0.1%',
        sub: 'commissions on every transaction made by affiliates',
      },
      {
        id: 2,
        main: 'Access',
        sub: 'a powerful partner dashboard to track key metrics',
      },
      {
        id: 3,
        main: 'Get onboarded',
        sub: 'in minutes and receive fast incentive settlements',
      },
    ],
    recommended: [
      'Web Developers & consultants',
      'Social media influencers',
      'Freelancers',
      'VC & Investors',
      'Ecommerce platforms',
      'Incubators & Co-working spaces',
    ],
    button: {
      text: 'Become an Affiliate Partner',
      link:
        'https://partner.cashfree.com/partner-ui/authentication/signup?source-action=partner%20program&action=Sign%20Up&button-id=StartNow_BecomeAffilatePartner',
    },
    small: '*TnC: Applied',
  },
  {
    id: 1,
    icon: '/img/partner/purple.svg',
    title: 'Platform Partner',
    description:
      'Plug ready to use payment solutions to your platform and offer the best payment solutions to businesses on your platform',
    checklist: [
      {
        id: 0,
        main: 'Customized',
        sub: 'incentive plan',
      },
      {
        id: 1,
        main: 'Activate 120+',
        sub: 'payment options for your partner businesses',
      },
      {
        id: 2,
        main: 'Get 24*7',
        sub: 'priority support and dedicated account managers',
      },
      {
        id: 3,
        main: 'Provide discounted',
        sub: 'pricing and free credits to your clients',
      },
    ],
    recommended: [
      'Ecommerce platforms',
      'SAAS platforms',
      'ERP platforms ',
      'Other Software Companies',
    ],
    button: {
      text: 'Become a Platform Partner',
      link:
        'https://www.cashfree.com/contact-sales?source-action=partner%20program&action=Sign%20Up&button-id=ContactSales_BecomePlatformPartner',
    },
    small: null,
  },
];
export const joinSliderData = items.map((i) => ({
  id: i.id,
  icon: `/img/partner/icons/${i.id}.svg`,
  title: i.title,
  description: <p className="text-2sm md:text-md">{i.text}</p>,
}));

export const heroLogoData = [
  {
    id: 0,
    src: 'shopify.png',
    width: '416',
    height: '128',
    className: 'max-w-[104px]',
    alt: 'Shopify',
  },
  {
    id: 1,
    src: 'wix.png',
    width: '216',
    height: '131',
    className: 'max-w-[54px]',
    alt: 'Wix',
  },
  {
    id: 2,
    src: 'zoho-books.png',
    width: '160',
    height: '152',
    className: 'max-w-[40px]',
    alt: 'Zohobook',
  },
  {
    id: 3,
    src: 'nasscom.png',
    width: '428',
    height: '60',
    className: 'max-w-[107px]',
    alt: 'Nasscom',
  },
  {
    id: 4,
    src: 'dlocal.png',
    width: '380',
    height: '120',
    className: 'max-w-[95px]',
    alt: 'd.local',
  },
  {
    id: 5,
    src: 'springboard.png',
    width: '496',
    height: '116',
    className: 'max-w-[124px]',
    alt: 'Springboard',
  },
  {
    id: 6,
    src: 'nimbbl.png',
    width: '358',
    height: '64',
    className: 'max-w-[89px]',
    alt: 'nimbbl.',
  },
  {
    id: 7,
    src: 'xpresslane.png',
    width: '360',
    height: '72',
    className: 'max-w-[90px]',
    alt: 'xpresslane',
  },
  {
    id: 8,
    src: 'zoho.png',
    width: '288',
    height: '113',
    className: 'max-w-[72px]',
    alt: 'zoho',
  },
  {
    id: 9,
    src: 'travclan.png',
    width: '340',
    height: '96',
    className: 'max-w-[85px]',
    alt: 'travclan',
  },
  {
    id: 10,
    src: 'nsrcel.png',
    width: '183',
    height: '136',
    className: 'max-w-[45px]',
    alt: 'nsrcel',
  },
  {
    id: 11,
    src: 'edchemy.png',
    width: '356',
    height: '84',
    className: 'max-w-[89px]',
    alt: 'edchemy',
  },
  {
    id: 12,
    src: 'getvantage.png',
    width: '416',
    height: '88',
    className: 'max-w-[104px]',
    alt: 'getvantage',
  },
  {
    id: 13,
    src: 'velocity.png',
    width: '316',
    height: '91',
    className: 'max-w-[79px]',
    alt: 'velocity',
  },
  {
    id: 14,
    src: 'hubco.png',
    width: '328',
    height: '108',
    className: 'max-w-[82px]',
    alt: 'hubco',
  },
  {
    id: 15,
    src: 'bhartipay.png',
    width: '268',
    height: '200',
    className: 'max-w-[67px]',
    alt: 'hubco',
  },
  {
    id: 16,
    src: 'pragma.png',
    width: '416',
    height: '74',
    className: 'max-w-[104px]',
    alt: 'hubco',
  },
];

export const heroSliderSettings = {
  dots: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  speed: 2000,
  variableWidth: false,
  slidesToShow: 9,
  infinite: true,
  rtl: true,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        rtl: true,
        autoplay: true,
        cssEase: 'linear',
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        centerMode: true,
        variableWidth: true,
      },
    },
  ],
};

export const faqData = [
  {
    id: 0,
    q: 'Who can become a Cashfree Partner?',
    a: (
      <p>
        Any individual, proprietary firm, partnership firm (including LLP) or a
        private limited company can register with Cashfree as a partner.
      </p>
    ),
  },
  {
    id: 1,
    q: 'How much time does the partner onboarding take?',
    a: (
      <>
        <p>
          Once you submit the contact form, you will get a call from our team
          within the next 6 business hours. Tell us about your business and in
          what capacity you would like to partner with Cashfree.
        </p>
        <p>
          For example a Payroll company may like to partner with Cashfree to
          strengthen its existing product capabilities and enable direct salary
          disbursals after the payroll computation while a marketing agency may
          want to engage as an affiliate and refer customers to Cashfree and
          earn rewards.
        </p>
      </>
    ),
  },
  {
    id: 2,
    q: 'What is the criteria for adding new customer accounts?',
    a: (
      <p>
        The customer business must be transacting and should not be involved in
        offering any of the prohibited items mentione{' '}
        <a
          href="https://www.cashfree.com/tnc"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          here
        </a>
        .
      </p>
    ),
  },
  {
    id: 3,
    q: 'What are the documents needed for adding new customer accounts?',
    a: (
      <p className="faq-p">
        Documents required for new customer account are listed{' '}
        <a
          href="http://help.cashfree.com/articles/1957862-what-are-the-documents-required-at-the-time-of-sign-up"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          here
        </a>
        .
      </p>
    ),
  },
  {
    id: 4,
    q: 'How can I access the partner dashboard? Can I add merchants by myself?',
    a: (
      <p>
        You can access the partner dashboard and start adding the merchants once
        your partner account is activated. To login, please click{' '}
        <a
          href="https://partner.cashfree.com/partner-ui/login"
          target="_blank"
          className="external-link"
          rel="noreferrer">
          here
        </a>
        .
      </p>
    ),
  },
  {
    id: 5,
    q: 'I am a Cashfree merchant, can I also register as Partner?',
    a: (
      <p>
        Yes, an existing Cashfree merchant can register as a Partner as well. In
        such a scenario you will be managing your merchant account and also get
        access to a sub dashboard where you can add new customer accounts, get a
        summary of their transactions on your partner dashboard and earn
        commission on every transaction they make. The payout of commission will
        happen periodically.
      </p>
    ),
  },
  {
    id: 6,
    q:
      'What are the SaaS platforms that can integrate Cashfree into their products?',
    a: (
      <>
        <p>
          Payments is an integral part of any online business. We have seen SaaS
          products extend their product offerings by integrating Cashfree with
          their product. For example Payroll companies partner with Cashfree to
          offer its customer companies the option to use Cashfree Payouts-a
          banking API to disburse salaries directly from the Payroll software
          after payroll computation. Other SaaS companies exploring Cashfree as
          technology partner include:
        </p>
        <ul className="list-disc">
          <li>HRM & Payroll Management</li>
          <li>Loan Management Solution</li>
          <li>Hotel Management (Booking engine)</li>
          <li>School Management Software</li>
          <li>Restaurant Billing Software</li>
          <li>ERP</li>
          <li>CRM</li>
          <li>Distributor Management System</li>
          <li>Supply chain management</li>
          <li>Expense Management</li>
          <li>E-procurement software</li>
          <li>Accounting</li>
          <li>Payment Gateways</li>
          <li>Dealer Management System</li>
        </ul>
      </>
    ),
  },
];
