import React, { useRef } from 'react';
import FAQ from '../components/FAQ';
import GetStarted from '../components/GetStarted';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import HeroLogoSlider from '../components/Sliders/HeroLogoSlider/HeroLogoSlider';
import PartnerQuoteSlider from '../components/Sliders/PartnerQuoteSlider/PartnerQuoteSlider';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import {
  models,
  heroLogoData,
  heroSliderSettings,
  faqData,
} from '../content/partner';
import '../styles/partner.scss';

function PartnerPage(rest) {
  const container = useRef();
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://partner.cashfree.com/partner-ui/authentication/login?source-action=partner%20program&action=Sign%20In&button-id=StartIn_Navbar"
      createAccountLink="https://partner.cashfree.com/partner-ui/authentication/signup?source-action=partner%20program&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Cashfree Partner Program. Earn with every transaction made."
        description="Refer Cashfree to your customers to simplify payments and earn on every transaction."
        url="https://www.cashfree.com/partner-program/"
        keywords={[
          'Cashfree Partner Page',
          'Cashfree Partner Program',
          'Cashfree Affiliate Partner Program',
        ]}
      />
      <section
        className="pt-[110px] pb-[36px] md:pb-[43px] bg-cf-primary  partner-hero overflow-hidden relative"
        ref={container}>
        <div className="container position-relative gradient-bg">
          <div className="flex flex-wrap md:justify-between">
            <div className="relative z-[1] w-full md:w-[42%] lg:self-center text-white">
              <h2 className="text-sm font-semibold  pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Partners Program
              </h2>
              <div className="mb-5 text-[27px] text-xl md:text-2xl font-semibold max-w-[470px] font-heading">
                Refer, Earn and Grow your Business
              </div>
              <div className="text-opacity-80 mb-8 max-w-[500px] text-[16px] leading-[24px] md:text-2.5md">
                Offer best-in-class payment solutions to your affiliates, and
                earn on every transaction they&nbsp;make.
              </div>
              <a
                className="button button-green wide w-full md:w-auto"
                href="https://partner.cashfree.com/partner-ui/authentication/signup?source-action=partner%20program&action=Sign%20Up&button-id=StartNow_Hero">
                Become A Partner <span className="chevron" />
              </a>
            </div>
            <div className="relative z-[1] w-full md:w-[58%] mt-14 md:mt-0">
              <div className="mx-auto max-w-[500px] md:-mt-10">
                <TransparentVideo
                  backgroundRef={container}
                  className="relative z-10"
                  poster="/img/partner/placeholder.png"
                  width="1100"
                  height="1300"
                  mp4Src="/img/partner/hero-video.mp4"
                  webmSrc="/img/partner/hero-video.webm"
                />
              </div>
            </div>
          </div>
        </div>
        <section className="mt-[36px] md:mt-8 logos text-white">
          <p className="md:text-md text-2.5sm mb-1 md:mb-6 text-center">
            Join our growing Partners ecosystem
          </p>
          <div className="w-full">
            <HeroLogoSlider
              data={heroLogoData}
              className=""
              imgUrl="/img/partner/logos/"
              itemClassName="mx-[25px] lg:mx-auto flex items-center align-center h-[50px]"
              settings={heroSliderSettings}
            />
          </div>
        </section>
      </section>
      <section className="p-section-2 relative overflow-hidden bg-cf-light-grey join-program">
        <div className="container">
          <div className="flex flex-wrap flex-col-reverse md:flex-row">
            <div className="w-full md:w-1/2 mt-8 md:mb-0">
              <picture>
                <img
                  src="/img/partner/join-program.svg"
                  alt="join program"
                  width="659"
                  height="548"
                  className="w-full"
                />
              </picture>
            </div>
            <div className="w-full md:w-1/2 md:pl-16 md:self-center">
              <div className="text-shs md:text-shl font-heading font-semibold">
                Who can join the Cashfree Partner program?
              </div>
              <div className="text-base max-w-[540px] md:mt-5 mt-3 mb-6 md:mb-8">
                Our partner community includes all kinds of businesses from
                individual entrepreneurs to multi billion dollar enterprises.
              </div>
              <a
                className="button button-green wide w-full md:w-auto"
                href="https://partner.cashfree.com/partner-ui/authentication/signup?source-action=partner%20program&action=Sign%20Up&button-id=StartNow_WhoCanJoinCFPP">
                Become A Partner <span className="chevron" />
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 text-white overflow-hidden bg-cf-hero partnership-section relative">
        <div className="container relative z-[1]">
          <div className="font-heading md:text-center text-shs md:text-shl  font-semibold mx-auto max-w-[610px] mb-5">
            Choose the right partnership model for&nbsp;you
          </div>
          <div className="text-base text-cf-cold-purple md:text-center max-w-[610px] mx-auto mb-[13px] md:mb-10">
            Cashfree offers Industry’s most rewarding partner program with quick
            & hassle-free onboarding and fast settlements
          </div>
          <div className="mx-[-19px] flex flex-wrap">
            {models.map((m) => (
              <div
                className="p-[19px] w-full md:w-1/2 last:pt-[6px] last:pb-[0px] md:last:pt-[19px] md:last:pb-[19px] group"
                key={m.id}>
                <div className="p-6 md:p-10 bg-white bg-opacity-10 md:h-full  flex flex-col md:justify-between rounded">
                  <div>
                    <div>
                      <div className="w-[32px] md:w-[48px] h-[32px] md:h-[48px]">
                        <img src={m.icon} className="w-full" alt="" />
                      </div>
                      <div className="text-md md:text-3md font-semibold mt-4">
                        {m.title}
                      </div>
                      <div className="text-2sm md:text-base pt-3 pb-6 md:pb-8">
                        {m.description}
                      </div>
                      <div className="text-cf-yellow text-2sm font-semibold mb-3">
                        Perks
                      </div>
                      <ul className="p-0 list-none max-w-full pb-8 border-b border-cf-stroke md:border-none border-opacity-40 mb-6 md:mb-0">
                        {m.checklist.map(({ id, sub, main }) => (
                          <li className="mb-3 last:mb-0 flex" key={id}>
                            <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-2 bg-cf-primary flex-none">
                              <Icon
                                name="tick"
                                width="9.21px"
                                height="6.29px"
                                fill="#fff"
                              />
                            </span>
                            <div className="text-2sm">
                              <span>{main}</span>{' '}
                              <span className="text-cf-cold-purple">{sub}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div className="text-cf-yellow text-2sm font-semibold mb-3 inline md:block">
                      Recommended for{' '}
                      <span className="inline md:hidden">- </span>
                    </div>
                    <div className="group-last:lg:pr-24 text-2sm inline md:block">
                      {m.recommended.join(' | ')}
                    </div>
                  </div>
                  <a
                    className="button button-green wide w-full md:w-max mt-8 md:mt-2"
                    href={m.button.link}>
                    {m.button.text} <span className="chevron" />
                  </a>
                </div>
                {m.small && (
                  <div className="text-white pt-3 text-tiny italic">
                    {m.small}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="p-section-2 bg-cf-light-grey overflow-hidden">
        <div className="container">
          <div className="font-heading md:text-center text-shs md:text-shl font-semibold mx-auto max-w-[610px] mb-8 md:mb-16">
            What do our Partners&nbsp;say?
          </div>
          <PartnerQuoteSlider />
        </div>
      </section>
      <section>
        <div className="container general-faqs">
          <FAQ open="0" data={faqData} />
        </div>
      </section>
      <GetStarted
        startNowLink="https://partner.cashfree.com/partner-ui/authentication/signup?source-action=partner%20program&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=partner%20program&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default PartnerPage;
